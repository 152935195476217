import React, { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

// Custom UI
import Spinner from '../../../components/Spinner';
import Alert from '../../../components/Alert';

// Helpers
import { Controller, FormProvider, useForm } from 'react-hook-form';

// Context
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../theme/ThemeContext';

export default function ForgottenPasswordFields() {

  const { t } = useTranslation();
  const theme = useTheme();

  const auth = useAuth();
  const [hasError, setHasError] = useState(false);

  const [isSettingPasswords, setIsSettingPasswords] = useState(false);
  const [passwordResetRequested, setPasswordResetRequested] = useState(false);

  const CentreItem = styled(Container)(({ theme }) => ({
    textAlign: 'center',
  }));

  const onSubmit = async (params) => {
    console.log("RESET PASSWORD", params)

    setIsSettingPasswords(true);

    const config = {
      email: params.email,
      shop: params.shop,
    }

    try {
      const requestReset = await auth.forgottenPassword(config);
      if (requestReset) {
        console.log('Reset password', requestReset);
        setPasswordResetRequested(true);
      } else {
        setIsSettingPasswords(false);
        setHasError(true);
      }
    } catch (error) {
      setHasError(true);
      setIsSettingPasswords(false);
    }

  }

  const { handleSubmit, control, register, formState: { errors } } = useForm({
    defaultValues: {
      email: ''
    }
  });

  if (passwordResetRequested) {
    return (
      <Container>
        <Stack spacing={2} sx={{ margin: "1rem 2rem" }}>
          <Typography component="h1" variant="h1" align='center'>{t('page_forgotten_password.text_success_title')}</Typography>
          <Typography component="p" variant="body1" align='center'>{t('page_forgotten_password.text_success', { brand: theme.settings.displayName })}</Typography>
          <Typography component="p" variant="body1" align='center'>{t('page_forgotten_password.text_success_info', { brand: theme.settings.displayName })} <Link href={`mailto:${theme.settings.supportEmail}`}>{theme.settings.supportEmail}</Link></Typography>
        </Stack>
      </Container>
    )
  }

  return (
    <Container>
      <FormProvider>
        { isSettingPasswords ? <Spinner /> :
          <CentreItem>
            <Stack spacing={2}>
              <Typography component="h1" variant="h1">{t('page_forgotten_password.text_title')}</Typography>
              <Typography component="p" variant="body1" align='center'>{t('page_forgotten_password.text_body', { brand: theme.settings.displayName })}</Typography>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" id="shop" name="shop" value={theme.settings.shop} {...register('shop')} />
              <Stack spacing={1} sx={{ margin: "1rem 2rem" }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => <OutlinedInput
                    {...field}
                    required={true}
                    type="email"
                    placeholder={t('page_login.field_email_label', { brand: theme.settings.displayName })}
                    error={!!errors.email}
                    variant="outlined"
                  />}
                />
              </Stack>
              <CentreItem>
                <Button type="submit" variant="contained" fullWidth>{t('page_forgotten_password.btn_set_password')}</Button>
              </CentreItem>
            </form>
          </CentreItem>
        }
      </FormProvider>
      { hasError && !isSettingPasswords &&
        <Alert severity="warning" showPatch={false} message={t('page_forgotten_password.text_error', { email: theme.settings.supportEmail })} />
      }
    </Container>
  );
}
