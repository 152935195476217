import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Custom UI
import LoginFields from './components/LoginFields';

// Hooks
import { useTheme } from '../../theme/ThemeContext';

const Center = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
}));

const LogoBox = styled(Box)(({ theme }) => ({
  maxWidth: "200px",
  margin: "3rem auto",
  "& img": {
    width: "100%",
  }
}));

export default function LoginPage({showPasswordResetPopup}) {

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Container sx={{ flex: 1 }}>
        <Center>
          <LogoBox>
            <img src={theme.getLogoSrc()} alt="Swap Platform Logo" loading="lazy" width="100" />
          </LogoBox>
          <Box style={{ marginBottom: "1rem" }}>
            <Typography component="h1" variant="h1">{t('page_account_create.text_title')}</Typography>
          </Box>
        </Center>
        <LoginFields showPasswordResetPopup={showPasswordResetPopup} />
      </Container>
      <Typography variant="body1" sx={{ textAlign: 'center', p: 2}}>
        {t('page_login.text_account')} <Link component={RouterLink} to={`auth/login`} underline="always">{t('page_account_create.link_signin')}</Link>
      </Typography>
    </>
  );
}
