import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

// Custom UI
import TokenListItem from './components/TokenListItem';
import PageContainer from '../../components/PageContainer';
import Alert from '../../components/Alert';

// Custom UI
import PageTitle from '../../components/PageTitle';
import Spinner from '../../components/Spinner';

// Helpers
import useFetch from 'use-http';

// Context
import { useAuth } from '../../context/AuthContext';
import { Divider } from '@mui/material';

export default function ItemsPage() {

  const { t } = useTranslation();

  const history = useHistory();

  const [tokens, setTokens] = useState([]);
  const [checkTokens, setCheckTokens] = useState();
  // const [isLoading, setIsLoading] = useState(true);

  const { user } = useAuth();

  const { post, get, response, loading } = useFetch('tokens');

  useEffect(() => {
    async function initializeSwaps() {
      const tokens = await post('search', {});
      const checkTokens = await get('check');

      if (response.ok) {
        console.log('Setting tokens', tokens);
        setTokens(tokens.records);
        setCheckTokens(checkTokens);
        // setIsLoading(false);
      }
    }

    initializeSwaps()
  }, [post, response, get]);

  const handleTokenClick = (token) => {
    history.push({
      pathname: `./tokens/${token.id}`,
      state: { token }
    })
  }

  const handleCreateToken = () => {
    history.push({
      pathname: '/app/tokens/create'
    })
  }

  const tokensOpen = tokens.filter(t => t.status === 'open' || t.status === 'processing') || [];
  const tokensRedeemed = tokens.filter(t => t.status === 'redeemed') || [];
  const tokensExpired = tokens.filter(t => t.status === 'expired') || [];

  return (
    <PageContainer>

      <Typography textAlign="center" component="h1" variant='h1'>
        {t('page_tokens.text_title')}
      </Typography>

      <Typography textAlign="center" component="h2" variant='h2'>
        <Chip label={loading ? <Spinner sx={{ mb: '12px' }} size={20} /> : `${t('page_tokens.text_remaining')} ${checkTokens && checkTokens.tokens}`} color="primary" variant="outlined" sx={{ mt: 2, mb: 2, paddingLeft: '1rem', paddingRight: '1rem', minWidth: '200px' }} />
        { !loading && checkTokens && checkTokens.tokens > 0 ? <Button sx={{ ml: 1 }} variant="outlined" size="small" onClick={handleCreateToken}>{t('swap_details.btn_create')}</Button> : <></>}
      </Typography>

      <Typography component="h2" variant='h2'>
        {t('page_tokens.text_title_redeemed')}
      </Typography>

      <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
        { loading &&
          <Spinner />
        }
        {tokensRedeemed.length > 0 && tokensRedeemed.map((token, i) => (
          <TokenListItem
            token={token}
            key={token.id}
            onClick={() => handleTokenClick(token)}
           />
        ))}
        { !tokensRedeemed.length && !loading &&
          <Box sx={{paddingBlock:2}}>
            <Alert message={t('page_tokens.text_warning_redeemed_none')} severity="info" showPatch />
          </Box>
        }
      </Paper>

      <Typography component="h2" variant='h2'>
        {t('page_tokens.text_title_open')}
      </Typography>

      <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
        { loading &&
          <Spinner />
        }
        {tokensOpen.length > 0 && tokensOpen.map((token, i) => (
          <TokenListItem
            token={token}
            key={token.id}
            onClick={() => handleTokenClick(token)}
          />
        ))}
        { !tokensOpen.length && !loading &&
          <Box sx={{paddingBlock:2}}>
            <Alert message={t('page_tokens.text_warning_open_none')} severity="info" showPatch />
          </Box>
        }
      </Paper>

      {
        (tokensExpired.length > 0) && (
          <>
            <Typography component="h2" variant='h2'>
              {t('page_tokens.text_title_expired')}
            </Typography>

            <Paper sx={{ mb: 6, mt: 2 }} elevation={3}>
              { loading &&
                <Spinner />
              }
              {!loading && tokensExpired.length > 0 && tokensExpired.map((token, i) => (
                <TokenListItem
                  token={token}
                  key={token.id}
                  onClick={() => handleTokenClick(token)}
                />
              ))}
              { !tokensExpired.length && !loading &&
                <Box sx={{paddingBlock:2}}>
                  <Alert message={t('page_tokens.text_warning_expired_none')} severity="info" showPatch />
                </Box>
              }
            </Paper>
          </>
        )
      }
    </PageContainer>
  );
}
