import React from "react";
import { useHistory } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';

// Hooks
import { useTheme } from '../../theme/ThemeContext'

export default function HowItWorks() {
  const { t } = useTranslation();
  const theme = useTheme();

  const history = useHistory()

  const conditions = [
    {
      condition: "New with tags",
      description:
        "The tags are still attached and/or it's packed in the original packaging. Never used and has no flaws.",
    },
    {
      condition: "New without tags",
      description:
        "Brand new, but the tags or original packaging isn't there. Never used and has no flaws.",
    },
    {
      condition: "Very good",
      description:
        "Used a few times, but still looks great. It might have a few slight imperfections - make sure you mention that!",
    },
    {
      condition: "Good",
      description:
        "Used and may have signs of wear, which are clearly mentioned in your listing.",
    },
    {
      condition: "Satisfactory",
      description:
        "Used a lot and has some imperfections, which are clearly mentioned in your listing.",
    },
  ];

  return (
    <Container disableGutters maxWidth="md">

      <Stack sx={{ p:2, m:2 }} spacing={2}>

        <Typography component="h1" variant="h1" align="center" sx={{ mb:2 }}>Swaps how to guide</Typography>

        <Typography component="p" variant="p">
          Welcome to swapping with {theme.getBrandName()}. Instead of returning,
          swapping an item that isn't quite right for you helps us do better for
          the planet and the people supported in education. Let's help you get
          started with your first swap!
        </Typography>

        <Typography component="h2" variant="h2" sx={{ pt:2 }}>Getting started</Typography>

        <Typography component="p" variant="p">
          On our welcome page, you have a couple of options - swap your items, or
          browse everything that has been listed by others. If you want to start
          with swapping your own items, you'll see all your items that you've
          purchased directly from {theme.getBrandName()} listed under My Items. You can select
          list this on any of these items and add the additional information
          required and then complete this with the list this button. Once listed
          you can select swap this on the item to search for your perfect swap or
          you can edit any details by selecting the pencil icon.
        </Typography>

        <Typography component="p" variant="p">
          If the item you want to swap isn't listed in your purchased items, you
          can add it.
        </Typography>

        <Typography component="h2" variant="h2" sx={{ pt:2 }}>Adding an item</Typography>

        <Typography component="p" variant="p">
          To add an item you have, you can search for a product by name, or by
          using filters. Once you've found the right item, select it and add
          information like the size, reason, condition and add any extra useful
          details.
        </Typography>

        <Typography component="p" variant="p">
          The item condition helps other people know what they can expect from
          a swap. For this reason, it's important to stay as objective as possible
          when deciding which condition your item falls into and to include as
          much information as you can if there are any imperfections. Here's more
          details on what each condition covers:
        </Typography>

        <Box sx={{ mt: 2, mb: 2 }}>
          <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Condition</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conditions.map((row) => (
                  <TableRow
                    key={row.condition}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.condition}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Typography component="p" variant="p">
          Once you're happy with your selections, go to the next step, where you
          can select the item/s you would consider swapping for. You can include a
          preferred size, or skip this. To finish listing your swap, confirm -
          that's it!
        </Typography>

        <Typography component="h2" variant="h2" sx={{ pt:2 }}>Finding something you like</Typography>

        <Typography component="p" variant="p">
          Using the search and filters you can search all the available swaps that
          have been listed by other people.
        </Typography>

        <Typography component="p" variant="p">
          Select an item to see all the product information like size, condition
          etc. If this item is exactly what you're looking for, select Choose Item
          - then it's a case of double checking your swap details and theirs. Once
          you're happy to proceed, request a swap and it will be sent over to the
          other person for review.
        </Typography>

        <Typography component="p" variant="p"  sx={{ pb:2 }}>
          If you would like more information on the measurements of a certain
          item, you can view our size guide here. Simply search for the name of
          the item you're interested in.
        </Typography>

      </Stack>
    </Container>
  );
}
